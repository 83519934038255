import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import DoubleScrollbar from "../components/DoubleScollbar"
import GradientImage from "../images/gradient-blue.png"

// Images
import BlueLabel from "../images/logo-blue-small.svg"
import SilverLabel from "../images/logo-silver-small.svg"
import GoldLabel from "../images/logo-gold-small.svg"

const Labeluebersicht = () => {
  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/labeloverview")
    }
  }, [])

  return (
    <React.Fragment>
      <PageTitle title={"Vergleich"} />
      <Layout>
        <div className="vergleich-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Alle Labels im Überblick</a>
              </li>
            </ul>
          </div>

          <div className="vergleich">
            <div className="vergleich-top">
              <h1>Übersicht Cyber Trust Austria Labels</h1>
              <div className="vergleich-horizontal-line"></div>
              <div className="vergleich-text">
                Es gibt drei Qualitätsstufen des Labels, um unterschiedliche
                Zielgruppen abdecken zu können. Die Qualitätsstufen
                unterscheiden sich hinsichtlich ihres Sicherheitsanspruchs
                (Security Claim) und des Sicherstellungsgrades der Überprüfung
                (Assurance Level).
              </div>
            </div>

            {/* <div class="vergleich__block--ghost--container">
              <div class="vergleich__block--ghost--slider"></div>
            </div> */}
            <div className="vergleich__block">
              <DoubleScrollbar>
                <div className="vergleich__table">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <div className="image">
                            <img
                              className="mw-100"
                              src={BlueLabel}
                              style={{ height: "59px" }}
                              alt="Cyber Trust Austria Standard Label"
                              title="Cyber Trust Austria Standard Label"
                            />
                          </div>
                        </th>
                        <th>
                          <div className="image">
                            <img
                              className="mw-100"
                              src={SilverLabel}
                              style={{ height: "59px" }}
                              alt="Cyber Trust Austria Silber Label"
                              title="Cyber Trust Austria Silber Label"
                            />
                          </div>
                        </th>
                        <th>
                          <div className="image">
                            <img
                              className="mw-100"
                              src={GoldLabel}
                              style={{ height: "59px" }}
                              alt="Cyber Trust Austria Gold Label"
                              title="Cyber Trust Austria Gold Label"
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td></td>
                        <td>Label</td>
                        <td>Label Silber</td>
                        <td>Label Gold</td>
                      </tr>
                      <tr>
                        <td>Zielgruppen</td>
                        <td>
                          KMUs, die Cybersicherheit ernst nehmen und dies nach
                          außen zeigen wollen Zulieferer von Betreibern
                          wesentlicher Dienste gemäß §16 NIS-Gesetz (BGBL Nr.
                          111/2018) in weniger kritischen Bereichen
                        </td>
                        <td>
                          Große Unternehmen, Zulieferer von Betreibern
                          wesentlicher Dienste gemäß §16 NIS-Gesetz (BGBL Nr.
                          111/2018) in kritischeren Bereichen (zB.
                          SW-Lieferanten, Verarbeiter sensibler Daten, etc.)
                        </td>
                        <td>
                          Große Unternehmen, Zulieferer von Betreibern
                          wesentlicher Dienste gemäß §16 NIS-Gesetz (BGBL Nr.
                          111/2018) in kritischeren Bereichen (zB.
                          SW-Lieferanten, Verarbeiter sensibler Daten, etc.)
                        </td>
                      </tr>
                      <tr>
                        <td>Basis</td>
                        <td>KSV1870 Cyber Risk B Rating</td>
                        <td>KSV1870 Cyber Risk A Rating</td>
                        <td>KSV1870 Cyber Risk A+ Rating</td>
                      </tr>
                      <tr>
                        <td>Assurance Level</td>
                        <td>Validierte Selbstdeklaration</td>
                        <td>Validierte Selbstdeklaration</td>
                        <td>
                          Validierte Selbstdeklaration plus externer Audit
                        </td>
                      </tr>
                      <tr>
                        <td>Assurance Methode</td>
                        <td>
                          Beantwortung eines Fragebogens mit Validierung;
                          Zustimmung zur möglichen Durchführung eines
                          stichprobenartigen Überprüfungs-Audits, Durchführung
                          eines automatisierten Web Risk Scoring
                        </td>
                        <td>
                          Beantwortung eines Fragebogens mit Validierung;
                          Zustimmung zur möglichen Durchführung eines
                          stichprobenartigen Überprüfungs-Audits, Durchführung
                          eines automatisierten Web Risk Scoring
                        </td>
                        <td>
                          Durchführung eines Audits durch einen qualifizierten
                          Prüfer (QuaSte-Akkreditierung) zur Prüfung der
                          Evidenzen; Durchführung eines automatisierten Web Risk
                          Scoring
                        </td>
                      </tr>
                      <tr>
                        <td>Security Claim</td>
                        <td>Baseline Security</td>
                        <td>Advanced Security</td>
                        <td>Advanced Security</td>
                      </tr>
                      <tr>
                        <td>Anzahl Kriterien</td>
                        <td>14</td>
                        <td>25</td>
                        <td>25</td>
                      </tr>
                      <tr>
                        <td>Anforderungen</td>
                        <td>Fragenliste B-Rating</td>
                        <td>Fragenliste A-Rating</td>
                        <td>Fragenliste A-Rating</td>
                      </tr>
                      <tr>
                        <td>Voraussetzung für Label</td>
                        <td>
                          Vorliegen eines gültigen KSV1870 CyberRisk B-Ratings
                          von 190 oder besser
                        </td>
                        <td>
                          Vorliegen eines gültigen KSV1870 CyberRisk A Ratings
                          von 190 oder besser
                        </td>
                        <td>
                          Vorliegen eines gültigen KSV1870 CyberRisk A+ Ratings
                          von 190 oder besser
                        </td>
                      </tr>{" "}
                      <tr>
                        <td>Gültigkeitsdauer</td>
                        <td>1 Jahr</td>
                        <td>1 Jahr</td>
                        <td>1 Jahr</td>
                      </tr>
                      <tr>
                        <td>Label Gebühr (1. Jahr)</td>
                        <td>890€ </td>
                        <td>1390€</td>
                        <td>1490€</td>
                      </tr>
                      <tr>
                        <td>Label Gebühr (Folgejahre)</td>
                        <td>790€</td>
                        <td>1190€</td>
                        <td>1290€</td>
                      </tr>
                      <tr>
                        <td>Gültigkeitsdauer</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Gemäß Audit-Partner</td>
                      </tr>
                      <tr>
                        <td>Erneuerungsprozess</td>
                        <td>
                          Erneute Beantwortung des Fragebogens*  <br />
                          <div className="vergleich__table--button">
                            <Link
                              to="/standard-label-beantragen"
                              className="page__btn page__btn--blue-border"
                              style={{
                                marginTop: 50,
                                padding: "14px 14px",
                                minWidth: 210,
                              }}
                            >
                              Ihr Weg zum Standard Label
                            </Link>
                            <a
                              className="page__btn page__btn--blue js-popup"
                              data-bg="#E2EAFF"
                              data-child="2"
                              data-btnbg="#1854A2"
                              data-rel="rel2"
                              style={{ padding: "14px 14px", minWidth: 210 }}
                              onClick={() => {
                                navigate("/label-beantragen?label=BLUE")
                              }}
                            >
                              Standard Label beantragen
                            </a>
                          </div>
                        </td>
                        <td>
                          Erneute Beantwortung des Fragebogens* <br />
                          <div className="vergleich__table--button">
                            <Link
                              to="/silber-label-beantragen"
                              className="page__btn page__btn--blue-border"
                              style={{
                                marginTop: 50,
                                padding: "14px 14px",
                                minWidth: 210,
                              }}
                            >
                              Ihr Weg zum Silber Label
                            </Link>
                            <a
                              className="page__btn page__btn--blue js-popup"
                              data-bg="#FFEBD1"
                              data-child="1"
                              data-btnbg="#C89E67"
                              data-rel="rel1"
                              style={{ padding: "14px 14px", minWidth: 210 }}
                              onClick={() => {
                                navigate("/label-beantragen?label=SILVER")
                              }}
                            >
                              Silber Label beantragen
                            </a>
                          </div>
                        </td>
                        <td>
                          Erneutes Audit* <br />
                          <br />
                          <div className="vergleich__table--button">
                            <Link
                              to="/gold-label-beantragen"
                              className="page__btn page__btn--blue-border"
                              style={{
                                marginTop: 50,
                                padding: "14px 14px",
                                minWidth: 210,
                              }}
                            >
                              Ihr Weg zum Gold Label
                            </Link>
                            <a
                              className="page__btn page__btn--blue js-popup"
                              data-bg="#FFEBD1"
                              data-child="1"
                              data-btnbg="#C89E67"
                              data-rel="rel1"
                              style={{ padding: "14px 14px", minWidth: 210 }}
                              onClick={() => {
                                navigate("/label-beantragen?label=GOLD")
                              }}
                            >
                              Gold Label beantragen
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </DoubleScrollbar>
            </div>

            <div className="asked-question__block--contact">
              <div>
                <h2>
                  <b>
                    Sind Sie nicht sicher, welches Label das Richtige für Ihr
                    Unternehmen ist? <br></br>
                    Lassen Sie sich von unseren Experten kostenlos beraten.
                  </b>
                </h2>
              </div>
              <div>
                <div>
                  <a
                    style={{
                      // border: "4px solid",
                      padding: "10px 10px",
                      width: 275,
                      textDecoration: "none",
                    }}
                    className="white-button-large page__btn page__btn--blue-border"
                    href="https://calendly.com/cyber-trust/30min"
                    target="_blank"
                  >
                    Zur kostenlosen Beratung
                  </a>
                </div>
              </div>
            </div>

            <div className="vergleich__bezeichnung">
              <sup>*</sup>Die Anforderungskriterien werden einer jährlichen
              Überprüfung unterzogen und im Bedarfsfall angepasst.
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default Labeluebersicht
